<template>
  <div>
    <div class="viewEdit">
      <b-card class=" timesheet-header">
        <!--view edit header -->
        <b-card class="mb-0">
          <!--view edit header -->
          <div class="card-header-edit">
            <p style=" color: #5e5873 !important;">
              Task Time Approval
            </p>
            <Help
              v-show="$store.state.app.userManualList"
              :code="userInfo.role == 'SA' ? 'TaskTimeLog' : 'TimesheetApproval'"
            />
          </div>
        </b-card>
      </b-card>
      <div class="card-filter-section">
        <div class="d-flex justify-content-between">
          <div
            cols="8"
            lg="9"
            class="d-block d-lg-flex align-items-center justify-content-start flex-wrap"
          >
            <p class="filter-title">
              Filters:
            </p>
            <custom-dropdown
              v-model="searchUser"
              :options="manageByUsers"
              multiple
              label="Members"
              icon="EditIcon"
            />
            <custom-dropdown
              v-model="searchStatus"
              :options="status"
              label="Status"
              multiple
              icon="EditIcon"
            />
            <option-drop-down v-model="perPage" />

          </div>

          <div class="d-flex">

            <b-input-group
              class="input-group-merge search-project mr-1"
              style="min-width: 250px; max-width: 250px; width: 250px"
            >
              <b-form-input
                v-model="debouncedSearch"
                placeholder="Search by name"
              />

              <b-input-group-append is-text>
                <feather-icon
                  v-if="debouncedSearch"
                  icon="XIcon"
                  @click="debouncedSearch = null, search = null"
                />
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>

            <button
              class="apply-filter filterRedesign mr-1"
              @click="ApplyFilter()"
            >
              Apply Filter
            </button>
            <button
              class="apply-filter-clear filterRedesign"
              @click="clearFilter()"
            >
              Clear
            </button>

          </div>
        </div>
      </div>
    </div>
    <div
      ref="scrollContainer"
      class="all-timesheet"
      @scroll="updateScrollbarValue"
    >
      <employee-list
        :user-id="searchUser"
        :status="searchStatus"
        :search="search"
        :per-page="perPage"
        :current-page="currentPage"
        @page="currentPage=$event"
        @pageIndexUpdate="queryUpdate()"
      />
    </div>
  </div>
</template>
<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import { eventBus } from '@/main'
import EmployeeList from './EmployeeList.vue'
import debounce from 'lodash/debounce'

export default {
  components: {
    EmployeeList,
    customDropdown,
  },
  data() {
    return {
      users: [],
      searchUser: [],
      search: null,
      searchStatus: ['in_process'],
      status: [
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Submitted', value: 'in_process' },
      ],
      perPage: 100,
      currentPage: 1,
      pageIndex: 0,
      width: null,
    }
  },
  computed: {
    manageByUsers() {
      return this.sortedUser(this.users)
    },
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
      }, 500),
    },
  },
  watch: {
    perPage(nv, old) {
      if (nv != old) {
        this.currentPage = 1
      }
    },
  },
  mounted() {
    this.setFilterValue()
    setTimeout(() => {
      this.$refs.scrollContainer.scrollTop = this.$route.query.pageIndex
    }, 1000)
    this.reportingAndManageByUser()
  },
  methods: {
    setFilterValue() {
      if (this.$route.query) {
        this.searchUser = this.$route.query.member
          ? Array.isArray(this.$route.query.member) ? this.$route.query.member : [this.$route.query.member]
          : this.searchUser

        this.searchStatus = this.$route.query.status ? this.$route.query.status == 'all' ? [] : Array.isArray(this.$route.query.status) ? this.$route.query.status : [this.$route.query.status]
          : this.searchStatus
        this.search = this.$route.query.search
        this.perPage = this.$route.query.perPage
          ? this.$route.query.perPage
          : 100
        this.currentPage = this.$route.query.currentPage
          ? this.$route.query.currentPage
          : 1
      }
    },
    queryUpdate() {
      const query = {
        member: this.searchUser, status: this.searchStatus && this.searchStatus.length ? this.searchStatus : 'all', search: this.search, currentPage: this.currentPage, perPage: this.perPage, pageIndex: this.pageIndex,
      }

      this.routeQueryUpdate(query)
    },

    /** Not Yet Use */
    async manageByUserList() {
      const input = {}
      const response = await this.getHTTPPostResponse(
        'manageby-user-list',
        input,
        false,
      )
      if (response && response.status === 200) {
        const { data } = response
        this.users = data
        Object.values(response.data).forEach(element => {
          this.users = element.manage_by
        })
      }
    },

    /* Reporting And Manage By Users List */
    async reportingAndManageByUser() {
      const input = {
        project_id: this.searchProjects,
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'timesheet-user-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.users = data.user
      }
    },
    ApplyFilter() {
      this.queryUpdate()
      eventBus.$emit('approvalsTimeSheetList', 1)
      setTimeout(() => {
        const myElement = this.$refs.scrollContainer
        this.width = myElement.clientHeight
      }, 600)
    },
    clearFilter() {
      this.searchStatus = ['in_process']
      this.searchUser = []
      this.perPage = 100
      this.currentPage = 1
      this.search = null
      setTimeout(() => {
        this.queryUpdate()
        this.ApplyFilter()
      }, 10)
    },
    updateScrollbarValue() {
      this.pageIndex = this.$refs.scrollContainer.scrollTop
      // this.queryUpdate()
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/vieweditheader.scss";
@import "../../assets/scss/component-css/viewedit.scss";

</style>
